import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question4 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> লেনদেন কিভাবে করবো?</small> <br /> ➡️আপনি আপনার সকল লেনদেন
            আপনার এজেন্টের সাথে করবেন। এজেন্ট ব্যাতিত অন্য কারো সাথে ভুলেও
            লেনদেন করবেন না। আপনি বিকাশ/নগদ/রকেটে লেনদেন করতে পারেন। সেক্ষেত্রে
            এজেন্টের সাথে পরামর্শ করে তারপর লেনদেন করবেন।
            <br />
            <br />
            {/* ➡️বিকাশ/নগদ/রকেটঃ ** অনেকেই আছেন – নিজের বিকাশ এজেন্ট নাই বা নিজের
            বিকাশ এজেন্ট এ লেনদেন করতে রিস্ক মনে করেন। সেই ক্ষেত্রে কোম্পানীর
            বিকাশ এজেন্ট ব্যবহার করতে পারেন। যদি কোম্পানীর বিকাশ – নগদ এজেন্ট
            ব্যবহার করেন তাহলে কয়েনের দাম একটু বেশি হবে ।
            <small>Agent List Baaji</small> */}
            <small>Official Agent List Baaji365site</small>
            <small>
              <a href="www.linktoagents.com">www.linktoagents.com</a>
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question4;

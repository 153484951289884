import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-main-div">
      <p>
        Copyright © (2024) Agent List
        <a href=""> Baaji365.Site</a>
      </p>
      <p>
        Powered by <a href="">Shahed Sharif</a>
      </p>
      <p>About us | Policy & Terms | Contact Us</p>
    </div>
  );
};

export default Footer;

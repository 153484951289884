import React from "react";
import { Link, NavLink } from "react-router-dom";
import adminImg from "../../images/p-105.png";
import superIcon from "../../images/icons_.png";
import customerIcon from "../../images/customer-service-icon.webp";
import "./AgentCard.css";

const AgentCard = () => {
  return (
    <div className="main-agent-card-div">
      <div className="agent-card-div">
        <div className="single-agent-card">
          <NavLink className="" to="/adminPage">
            <img className="admin_img" src={adminImg} alt="" />
          </NavLink>
          <div className="agent-card-info_first">
            <h4>
              <a className="agent-link" href="/adminList">
                ADMIN
              </a>
            </h4>
            <p> Baaji365 এর এডমিনের সাথে যোগাযোগ করতে এডমিন বাটনে ক্লিক করুন</p>
          </div>
        </div>

        <div className="single-agent-card super">
          <NavLink className="" to="/adminPage">
            <img className="super_img" src={superIcon} alt="" />
          </NavLink>
          <div className="agent-card-info_first">
            <h4>
              <a className="agent-link" href="/SubadminList">
                SUB ADMIN
              </a>
            </h4>
            <p>
              {" "}
              Baaji365 এর সাব এডমিনের সাথে যোগাযোগ করতে এডমিন বাটনে ক্লিক করুন
            </p>
          </div>
        </div>

        <div className="single-agent-card super">
          <NavLink className="" to="/superAgentPage">
            <img className="super_img" src={superIcon} alt="" />
          </NavLink>
          <div className="agent-card-info_first">
            <h4>
              <a className="agent-link" href="/superAgentList">
                SUPER
              </a>
            </h4>
            <p>
              {" "}
              Baaji365 এর সুপার এজেন্টদের সাথে যোগাযোগ করতে বাটনে ক্লিক করুন{" "}
            </p>
          </div>
        </div>

        <div className="single-agent-card super">
          <NavLink className="" to="/masterAgentPage">
            <img className="super_img" src={superIcon} alt="" />
          </NavLink>
          <div className="agent-card-info_first">
            <h4>
              <a className="agent-link" href="/masterAgentList">
                MASTER
              </a>
            </h4>
            <p>Baaji365 এর এজেন্টদের সাথে যোগাযোগ করতে বাটনে ক্লিক করুন </p>
          </div>
        </div>

        {/* //Customer service */}
        <div className="single-agent-card super">
          <NavLink className="" to="/masterAgentPage">
            <img className="super_img" src={customerIcon} alt="" />
          </NavLink>
          <div className="agent-card-info">
            <h4>
              <a className="agent-link" href="/customerServiceList">
                CUSTOMER SERVICE
              </a>
            </h4>
            <p>
              Baaji365 CUSTOMER SERVICE এর সাথে যোগাযোগ করতে বাটনে ক্লিক করুন{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentCard;

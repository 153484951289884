import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";

const AgentListQuestion3 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            🎯কিভাবে বাজি ধরবো?
            <br />
            <br />
            আপনি এজেন্টদের কাছ থেকে একাউন্ট খুলে কয়েন কিনে নিন। তারপর নিচের
            লিংকে ক্লিক করে বাজি ওয়েবসাইটে গিয়ে আপনার পছন্দ মতো বাজি ধরতে পারেন।
            <br />
            <br />
            👉 এখানে বাজি ধরুন
            <br />
            <br />
            আপনি যদি না জানেন কিভাবে বাজি ধরতে হয় তাহলে আপনি আপনার এজেন্টের সাথে
            যোগাযোগ করুন। তারা আপনাকে পরিষ্কার ভাবে বুঝিয়ে দিবেন। আপনার
            এজেন্টদের সাথে যোগাযোগ করতে নিচের লিস্টে ক্লিক করে তাদের নামের উপর
            ক্লিক করুন। নাম খুঁজে না পেলে লিস্টের উপরের সার্চবারে সার্চ করুন।
            <br />
            <br />
            🎯 এখানে কি কি বাজি ধরা যায়?
            <br />
            <br />
            আপনি এজেন্টদের সাথে সরাসরি কথা বলে বাজির বিস্তারিত জানতে পারবেব।
            আমাদের এজেন্টরা ট্রাস্টেড এবং আপনি নিরাপদে বেটিং করতে পারেন। তবে
            যেকোনো বাজি ধরার আগে জেনে নিন আপনার এজেন্টের নাম এজেন্ট লিস্টে রয়েছে
            কিনা। যেকোন অভিযোগের জন্য এডমিন /সুপার এজেন্টদের সাথে যোগাযোগ করুন।
            <br />
            <br />
            এজেনেট সম্পর্কে কোন অভিযোগ থাকলে সরাসরি কাস্টমার সার্ভিসে যোগাযোগ
            করতে পারেন অবথা সুপার এজেন্ট/এডমিনকে জানাতে পারেন।
            <br />
            <br />
            🎯 এখানে কি কি বাজি ধরা যায়?
            <br />
            <br />
            আপনি অনেক ধরনের বাজিই ধরতে পারেন।এখানে ক্লিক করে ওয়েবসাইট ঘুরে দেখে
            আসতে পারেন
            <br />
            <br />
            ➡️ যেকোনো ক্রিকেট ম্যাচে বাজি ধরতে পারেন। প্রতি ম্যাচে, ওভারে বা বলে
            বলেও বাজি ধরতে পারেন।
            <br />
            <br />
            ➡️ IPL, BPL, PSL, T10 সহ সকল লিগ ম্যাচেও একইভাবে বাজি ধরতে পারেন।
            <br />
            <br />
            ➡️ সকল ফুটবল ম্যাচে বাজি গালাতে পারেন।
            <br />
            <br />
            ➡️ ক্যাসিনো খেলতে পারেন।
            <br />
            <br />
            ➡️ তিনপাত্তি সহ তাসের বাজি গুলোতে বেটিং করতে পারেন।
            <br />
            <br />
            এছাড়াও আরো অনেক মজার মজার বাজি রয়েছে, যেগুলোতে আপনি অনায়াসে বাজি
            ধরতে পারেন। আরো বিস্তারিত জানতে baji365-এর ওয়েবসাইটে ঘুরে আসুন নিচে
            ক্লিক করে।
            <br />
            <br />
            👉Velkii ওয়েবসাইট
            <br />
            <br />
            🎯 কিভাবে Velkii-এর ওয়েবসাইটে যাবো?
            <br />
            <br />
            আপনি এখানে ক্লিক করে Velkii এর ওয়েবসাইটে সরাসরি চলে যেতে পারেন।
            তারপর সেখানে নিরাপদে বেটিং করতে পারেন। বেটিং করার আগে আপনার একাউন্ট
            খুলে নিন। একাউন্ট খুলতে নিচে ক্লিক করুন।
            <br />
            <br />
            👉 একাউন্ট কিভাবে খুলবো?
            <br />
            <br />
            👉 Velkii ওয়েবসাইট
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AgentListQuestion3;

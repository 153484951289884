import React from "react";
import "./HeaderBanner.css";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import sliderImg3 from "../../images/02.jpg";
import sliderImg4 from "../../images/03.jpg";
import sliderImg5 from "../../images/04.png";
import sliderImg6 from "../../images/5.png";
import sliderImg7 from "../../images/6.jpg";
import sliderImg8 from "../../images/7.jpg";
import sliderImg9 from "../../images/8.jpg";
import sliderImg10 from "../../images/9.jpg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const HeaderBanner = () => {
  return (
    <div className="sliderContainer">
      <Swiper
        modules={[Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <img className="slider_img" src={sliderImg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg8} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg9} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="slider_img" src={sliderImg10} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeaderBanner;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question6 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> আমি কি কয়েন জিতলেই টাকা পাবো?</small> <br />
            ➡️জ্বী আপনি পয়েন্ট জিতলেই উইড্র করে এজেন্টদের কাছ থেকে টাকা নিয়ে
            নিতে পারেন। তবে আপনার সর্বনিম্ন উইড্র হচ্ছে ৫ পয়েন্ট বা ৫০০
            টাকা।বিস্তারিত জানতে এজেন্টের সাথে কথা বলুন।নিচে এজেন্ট লিস্ট দেয়া
            আছে। এজেন্ট লিস্টে গিয়ে তাদের হোয়াটসঅ্যাপ আইকনের উপর ক্লিক করলেই
            সরাসরি তাদের হোয়াটসঅ্যাপে যোগাযোগ করতে পারবেন।
            <br />
            <br />
            <small>➡️ অনলাইন মাস্টার এজেন্ট লিস্ট</small>
            <small>Agent list of baaji365site</small>
            <small>
              <a href="www.linktoagents.com">www.linktoagents.com</a>
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question6;

import React from "react";
import "./WebsiteLink.css";
import { NavLink } from "react-router-dom";

const WebsiteLink = () => {
  return (
    <>
      <div className="website-link-main-div">
        <div className="facebook-group-link">
          <p>আমাদের পেজ এ যান নিচে ক্লিক করে</p>
          <button>
            <a href="https://www.facebook.com/profile.php?id=61557672436381&mibextid=ZbWKwL">
              <i class="far fa-newspaper"></i>
              <span>Page</span>
            </a>
          </button>
        </div>

        <div className="facebook-group-link">
          <p>আমাদের ফেসবুক গ্রুপে যান নিচে ক্লিক করে</p>
          <button>
            <a href="https://www.facebook.com/groups/357732366546149/?ref=share_group_link">
              {" "}
              <i class="fa-brands fa-facebook"></i>
              <span>Group</span>
            </a>
          </button>
        </div>

        {/* <div className="facebook-group-link">
          <p>আমাদের অ্যাপ ডাউনলোড করুন নিচে ক্লিক করে</p>
          <button>
            <a href="">
              <i class="far fa-newspaper"></i>
              <span>Apps</span>
            </a>
          </button>
        </div> */}

        {/*  */}
      </div>
      <div className="hotline_number">
        <a href="https://wa.me/971566048674?text=">
          <button>
            <i class="fa-brands fa-whatsapp"></i>
            {/* <span> </span> */}
          </button>
        </a>
      </div>
    </>
  );
};

export default WebsiteLink;

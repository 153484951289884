import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";

const AgentListQuestion2 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            🎯এজেন্ট লিস্ট কোথায় পাবো?
            <br /> <br /> আপনি যদি নতুন ইউজার হোন তাহলে আপনি নিচে ক্লিক করে
            Velkii এর ফেইসবুক গ্রুপে যুক্ত হয়ে যান । কারণ, এখানে সকল অনুমোদিত
            এজেন্টরা রয়েছেন।
            <br />
            <br />
            👉ফেইসবুক গ্রুপ <br />
            <br /> ফেইসবুক গ্রুপে Velkii এর সকল তথ্য পেয়ে যাবেন।আপনি নিচের
            অনলাইন মাস্টার এজেন্ট লিস্ট থেকেও পছন্দ মতো এজেন্ট এর কাছে একাউন্ট
            খুলে নিরাপদে বেটিং করতে পারেন। নিচের লিস্টে ঢুকে এজেন্টদের
            হোয়াটসঅ্যাপ নাম্বারের উপর ক্লিক করলেই আপনি এজেন্টদের সাথে সরাসরি কথা
            বলতে পারবেন।
            <br />
            <br />
            👉 অনলাইন এজেন্ট লিস্ট
            <br />
            <br />
            আপনি যদি পুরাতন ইউজার হোন এবং আপনার পরিচিত অনেক ইউজার থেকে থাকে
            তাহলো আপনি নিজে এজেন্ট হয়ে বেট ধরিয়ে দিতে পারেন। সেজন্য আপনাকে সুপার
            এজেন্টের সাথে যোগাযোগ করতে হবে। এজেন্ট হতে চাইলে নিচে ক্লিক করুন।
            <br />
            <br />
            👉 আমি কি এজেন্ট হতে পারবো?
            <br />
            <br />
            🎯 এজেন্টদের সাথে যোগাযোগ করবো কিভাবে?
            <br />
            <br />
            আপনি এজেন্টদের সাথে যোগাযোগ করতে পারেন ফেসবুকে বা হোয়াটসঅ্যাপে।
            ফেসবুকে যোগাযোগ করতে হলে আপনি নিচের এজেন্ট লিস্টে ঢুকে এজেন্টদের
            নামের উপর ক্লিক করলেই সরাসরি এজেন্টদের সাথে ফেসবুকে যুক্ত হয়ে
            যাবেন।তারপর এজেন্টকে মেসেজ দিন। আর হোয়াটসঅ্যাপে কথা বলতে নিচের
            এজেন্ট লিস্টে ঢুকে নাম্বারের উপর ক্লিক করুন। তারপর এজেন্টকে মেসেজ
            দিন।
            <br />
            <br />
            👉 অনুমোদিত এজেন্টদের লিস্ট <br />
            <br />
            এই এজেন্ট লিষ্টের বাইরে কারো সাথে লেনদেন করলে আপনাকে নিজ দ্বায়িত্বে
            লেনদেন করতে হবে। লিষ্ট এর বাইরে কোন এজেন্ট এর দায়ভার Velkii নিবে না
            এবং লিষ্ট এর এজেন্ট দের সব দায়ভার Velkii কোম্পানীর। এজেন্ট লিষ্ট এর
            এজেন্ট দের সাথে দুই ভাবে যোগাযোগ করতে পারেন। ফেসবুক,এবং হোয়াটসাপ।
            আপনি নিচের ফেসবুক গ্রুপে যুক্ত হয়েও সকল এজেন্টদের সাথে যোগাযোগ করতে
            পারেন।
            <br />
            <br />
            👉 অনলাইন মাস্টার এজেন্ট গ্রুপ
            <br />
            🎯 কিভাবে Velkii-এর ওয়েবসাইটে যাবো?
            <br />
            <br />
            🎯 ফেসবুক গ্রুপে কিভাবে যুক্ত হবো?
            <br />
            <br />
            আপনি নিচের লিংকে ক্লিক করলেই আপনি সরাসরি ফেইসবুক গ্রুপে চলে যাবেন।
            ফেসবুকে ঢুকে গেলে তারপর Join -এ ক্লিক করে যুক্ত হয়ে যান।
            <br />
            <br />
            👉 ফেসবুক গ্রুপ I ফেইসবুক গ্রুপে সকল এজেন্ট, সুপার এজেন্ট এবং
            এডমিনরা রয়েছে। আপনার যেকোনো সমস্যা বা প্রয়োজনে আপনি সবাইকে পাশে
            পাবেন।
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AgentListQuestion2;

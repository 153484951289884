import React from 'react'
import Header from '../../Sheard/Header/Header'

const Home = () => {
    return (
        <>
            <Header />
        </>
    )
}

export default Home
import React from "react";
import AgentCard from "../../Component/AgentCard/AgentCard";
import FacebookGroup from "../../Component/FacebookGroup/FacebookGroup";
import Footer from "../../Component/Footer/Footer";
import GeneralQuestion from "../../Component/GeneralQuestion/GeneralQuestion";
import HeaderBanner from "../../Component/HeaderBanner/HeaderBanner";
import Navigation from "../../Component/Navigation/Navigation";
import UserId from "../../Component/UserId/UserId";
import WebsiteLink from "./../../Component/WebsiteLink/WebsiteLink";
import Heading from "../../Component/Heading/Heading";

const Header = () => {
  return (
    <>
      <Navigation />
      <Heading />
      <div className="same_color">
        <HeaderBanner />
        <AgentCard />
        <FacebookGroup />
        {/* <UserId /> */}
      </div>
      <GeneralQuestion />
      <WebsiteLink />
      <Footer />
    </>
  );
};

export default Header;

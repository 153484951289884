import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question3 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> কিভাবে কয়েন কিনবো?</small> <br /> এজেন্টের কাছ থেকে আপনাকে
            পয়েন্ট কিনতে হবে। কিন্তু পয়েন্ট কিনার আগে অবশ্যই আপনার পছন্দের
            এজেন্টের কাছ থেকে একাউন্ট করিয়ে নিবেন। পয়েন্ট বেচা এবং কেনার কাজ
            এজেন্টই করে দিবে। এজেন্ট আপনাকে একাউন্ট খোলার নিয়ম বলে দিবে। এজেন্ট
            কে ৫ পয়েন্ট এর টাকা দিলে, এজেন্ট আপনাকে একাউন্ট খুলে দিবে এবং আপনার
            একাউন্টে ৫ পয়েন্ট ডিপোজিট করে দিবে। আমাদের সাইটে সর্বনিম্ন ডিপোজিট
            এবং উইথড্র ৫০০ টাকা, অর্থাৎ ৫ পয়েন্ট।
            <br />
            <br />
            <small>অনলাইন মাস্টার এজেন্ট লিস্ট</small>
            <small>
              <a href="Baaji365.site">Baaji365.site</a> এর এজেন্ট লিস্ট।
            </small>
            <small>
              <a href="www.linktoagents.com">www.linktoagents.com</a>
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question3;

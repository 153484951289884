import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";
import "./AdminPage.css";
import adminImg from "../../images/FACEBOOK.jpg";
import { Link } from "react-router-dom";
import adminFooter from "../../images/agent_list.jpg";
import adminPic from "../../images/p-105.png";

const AdminPage = () => {
  return (
    <>
      <Navigation />

      <div>
        <div className="admin-background">
          <img src={adminImg} alt="" />
          <div className="opacity-color"></div>
          <div className="admin-background-info">
            <img className="adminPic" src={adminPic} alt="" />
            <a className="link_dres" href="/adminList">
              {" "}
              <h1>এডমিন</h1>
            </a>
          </div>
        </div>
        <div className="admin-details">
          <div className="admin-details-list">
            <p>
              Baaji365 এর এডমিনরা এখানে রয়েছেন । এডমিনদের সাথে যোগাযোগ করতে
              নিচের এডমিন লিস্টে ক্লিক করে তাদের WhatsApp আইকনের উপর ক্লিক করুন।
              আপনার যেকোনো অভিযোগের জন্য কমপ্লেইন এ ক্লিক করুন। ধন্যবাদ।
            </p>

            <Link to="/adminList">Admin List</Link>
          </div>

          <div className="admin-details-img">
            <img src={adminFooter} alt="" />
            <div className="content"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminPage;

import React from "react";
import "./FacebookGroup.css";
import facebookGroup from "../../images/FACEBOOK.jpg";
import { NavLink } from "react-router-dom";

const FacebookGroup = () => {
  return (
    <>
      <div className="facebook-group-img">
        <img src={facebookGroup} alt="" />
        <div className="facebook-group-info"> </div>
        <div className="facebook-group-details">
          <h2>Jᴏɪɴ ᴏᴜʀ Oғғɪᴄɪᴀʟ Fᴀᴄᴇʙᴏᴏᴋ Gʀᴏᴜᴘ</h2>
          <p>
            আমাদের সকল সুপার এজেন্ট, এজেন্ট ও ইউজার আমাদের ফেইসবুক গ্রুপে যুক্ত
            আছে। BAAJI365 -এর সকল আপডেট পেতে এক্ষুনি নিচের facebook চিহ্নিত
            বাটনে ক্লিক করে আমাদের অফিসিয়াল ফেসবুক গ্রুপে যুক্ত হয়ে যান।
          </p>

          <button>
            <a href="https://www.facebook.com/groups/357732366546149/?ref=share_group_link">
              Facebook Group
            </a>
          </button>
          <button>
            <a href="https://www.facebook.com/profile.php?id=61557672436381&mibextid=ZbWKwL">
              Facebook Page
            </a>
          </button>
        </div>
      </div>
      <p className="facebook_section">
        আপনার সকল জিজ্ঞাসা ও জানার বিষয়ে আমাদের গ্রুপে পোস্ট করুন। সকল এজেন্টদের
        থেকে আপনার পছন্দের এজেন্টকে বেছে নিন এবং নিরাপদে বেটিং করুন।
      </p>
    </>
  );
};

export default FacebookGroup;

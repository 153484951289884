import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";
import redCircle from "../../images/red-circle.png";
import "./QuestionPage.css";

const Question2 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small>আমি কি এজেন্ট হতে পারবো ?</small> <br /> জ্বী অবশ্যই হতে
            পারবেন। সেজন্য আপনাকে{" "}
            <a href="linktoagents.com ">linktoagents.com </a> এর সুপার এজেন্টদের
            সাথে যোগাযোগ করতে হবে।
            <br />
            <br />
            এজেন্ট হওয়ার শর্তঃ- <br />
            <br />
            ➡️ কমপক্ষে 2০০ কয়েন কিনে এজেন্টশিপ চালাতে হবে।
            <br />
            <div className="redCircle">
              <img src={redCircle} alt="" />
              {/* <p>
                {" "}
                উক্ত শর্ত দুটি পুরন করতে না পারলে কখনোই সুপার এজেন্টদের নক দিবেন
                না।
              </p> */}
            </div>
            <small>এজেন্ট পয়েন্ট রেটঃ-</small>
            <br />
            এজেন্টরা সুপার এজেন্টদের কাছ থেকে ৮0 টাকা করে কয়েন কিনবে পারবে এবং
            ১০০ টাকা করে ইজারদের কাছে বিক্রি করবে।
            <br />
            <br />
            <small>এজেন্ট এর লাভ/লস:-</small>
            <br />
            এজেন্টরা কয়েন প্রতি 20% লাভ পাবে। লাভ এবং লস বিস্তারিত জানতে সুপার
            এজেন্টদের নক করুন ।
            {/* <small>
              সুপার এজেন্ট লিস্ট।{" "}
              <a href="http://www.baajiagentlist.live/">
                www.gentlistbaaji.live
              </a>
            </small> */}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question2;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/01.gif";
import "./Navigation.css";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="navigation">
      <div>
        {isOpen ? (
          <button onClick={() => setIsOpen(!isOpen)} className="menu-bar-icon">
            <AiOutlineClose />
          </button>
        ) : (
          <button onClick={() => setIsOpen(!isOpen)} className="menu-bar-icon">
            <AiOutlineMenu />
          </button>
        )}
        <div className="main-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
      </div>
      <div className="nav-menubar ">
        <ul className={isOpen ? "nav-menu active" : "nav-menu "}>
          <li className="nav-menu-link">
            <NavLink to="/">HOME</NavLink>
          </li>

          <li className="nav-menu-link">
            <NavLink to="/adminList">ADMIN</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/SubAdminList">SUB ADMIN</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/superAgentList">SUPER</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/masterAgentList">MASTER</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/customerServiceList">HELPLINE</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/usercollection">USER QUESTION</NavLink>
          </li>
          <li className="nav-menu-link">
            <NavLink to="/siteupdate">SITE UPDATE NEWS</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;

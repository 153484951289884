import React from "react";
import Navigation from "./../../Component/Navigation/Navigation";
import adminImg from "../../images/FACEBOOK.jpg";
import adminFooter from "../../images/agent_list.jpg";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import superIcon from "../../images/icons_.png";
import "./SuperAgentPage.css";

const SuperAgentPage = () => {
  return (
    <>
      <Navigation />

      <div>
        <div className="admin-background">
          <img src={adminImg} alt="" />
          <div className="opacity-color"></div>
          <div className="admin-background-info super-agent">
            <img className="super_img__" src={superIcon} alt="" />
            <a className="link_dres" href="/superAgentList">
              {" "}
              <h1>সুপার এজেন্ট</h1>
            </a>
          </div>
        </div>
        <div className="admin-details">
          <div className="admin-details-list">
            <p>
              Baaji365 এর সকল সুপার এজেন্টদের লিস্ট এখানে রয়েছে ।সুপার এজেন্টদের
              সাথে যোগাযোগ করতে নিচের সুপার এজেন্ট লিস্টে ক্লিক করে তাদের ফেসবুক
              বা WhatsApp নাম্বারে ক্লিক করুন। লিস্টে দেয়া নামের উপর ক্লিক করে
              উনাদের ফেসবুক অ্যাকাউন্ট এ যুক্ত হন অথবা WhatsApp নাম্বারের উপর
              ক্লিক করে সরাসরি WHATSAPP অ্যাকাউন্ট এ কথা বলুন। যেকোনো অভিযোগের
              জন্য COMPLAIN এ ক্লিক করে অভিযোগ জানান।
            </p>

            <Link to="/superAgentList">Super List</Link>
          </div>

          <div className="admin-details-img">
            <img src={adminFooter} alt="" />
            <div className="content"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SuperAgentPage;

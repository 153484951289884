import React from "react";

const Heading = () => {
  return (
    <>
      <div className="heading-section">
        <p className="notis">নোটিশ</p>
        <marquee className="heading" width="100%" direction="left">
          বাজি খেলার জন্য যারা বিশ্বস্ত এবং নির্ভরযোগ্য সাইট খুঁজছেন
          BAAJI365.SITE হচ্ছে দেশের সেরা প্রিমিয়াম কোয়ালিটি বেটিং সাইট। যারা
          বেকার সমস্যায় ভুঁগছেন এবং বাজি খেলে অনেক লসে আছেন, তাদের জন্যই বেটিং
          বিজনেস। এবং বেটিং এর ব্যবসায় সফল হওয়ার জন্য প্রয়োজন বিশ্বস্ততায় ও
          নির্ভরতায় সেরা প্রিমিয়াম কোয়ালিটি বেটিং সাইট। আর আপনি বাজি খেলুন বা
          বাজির ব্যবসা করুন BAAJI365.SITE ই আপনার জন্য সেরা। এজেন্ট নিয়ে ব্যবসা
          করতে আগ্রহী হলে এডমিন সাব এডমিন এবং সুপার এজেন্টদের সাথে যোগাযোগ করুন।
          এই এজেন্ট লিস্টের কেউ আপনার সাথে প্রতারণা করলে সরাসরি এডমিনের সাথে
          যোগাযোগ করুন। ধন্যবাদান্তে! শাহেদ শরীফ।
        </marquee>
      </div>
    </>
  );
};

export default Heading;

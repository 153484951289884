import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question7 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> এজেন্ট লিস্ট কোথায় পাবো?</small> <br />
            আপনি যদি নতুন ইউজার হোন তাহলে আপনি নিচে ক্লিক করে{" "}
            <a href="Baaji365site">Baaji365site</a> এর ফেইসবুক গ্রুপে যুক্ত হয়ে
            যান। কারণ, এখানে সকল অনুমোদিত এজেন্টরা রয়েছেন।
            <br />
            <p>
              <a href="http://baaji365.site/">http://baaji365.site/</a>
            </p>
            <br />
            <small>➡️ ফেইসবুক গ্রুপ</small>
            <br />
            ফেইসবুক গ্রুপে <a href="Baaji365.site">Baaji365.site</a> এর সকল তথ্য
            পেয়ে যাবেন।আপনি নিচের অনলাইন মাস্টার এজেন্ট লিস্ট থেকেও পছন্দ মতো
            এজেন্ট এর কাছে একাউন্ট খুলে নিরাপদে বেটিং করতে পারেন। নিচের লিস্টে
            ঢুকে এজেন্টদের হোয়াটসঅ্যাপ আইকনের উপর ক্লিক করলেই আপনি এজেন্টদের
            সাথে হোয়াটসঅ্যাপে সরাসরি যোগাযোগ করতে পারবেন।
            <br />
            <br />
            <small>➡️ অনলাইন এজেন্ট লিস্ট।</small>
            <br />
            <small>
              <a href="www.linktoagents.com">www.linktoagents.com</a>
            </small>
            <br />
            আপনি যদি পুরাতন ইউজার হোন এবং আপনার পরিচিত অনেক ইউজার থেকে থাকে
            তাহলো আপনি নিজে এজেন্ট হয়ে বেট ধরিয়ে দিতে পারেন। সেজন্য আপনাকে সুপার
            এজেন্টের সাথে যোগাযোগ করতে হবে। এজেন্ট হতে চাইলে নিচে ক্লিক করুন।
            <br />
            <br />
            {/* <small>➡️ আমি কি এজেন্ট হতে পারবো?</small> */}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question7;

import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";
import "./QuestionPage.css";

const QuestionPage = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small>একাউন্ট কিভাবে খুলবো?</small> <br />{" "}
            <strong>Baaji365</strong> -এ একাউন্ট আপনি নিজে নিজে ওপেন করতে পারবেন
            না। একাউন্ট ওপেন করতে হলে আপনাকে এজেন্ট এর সাথে যোগাযোগ করতে হবে।
            এখানে এজেন্ট ই সব, এজেন্ট আপনাকে একাউন্ট খুলে দিবে। পয়েন্ট
            কেনা-বেচা এজেন্টের সাথেই আপনাকে করতে হবে।আপনি{" "}
            <a href="linktoagents.com">linktoagents.com </a> এর অন্তর্ভুক্ত
            যেকোনো মাস্টার এজেন্ট থেকে একাউন্ট খুলে বেট করতে পারেন।
            <br />
            <br />
            <small>➡️ অনলাইন এজেন্ট লিস্ট।</small> <br /> ➡️
            <a href="www.linktoagents.com">www.linktoagents.com</a>এই এজেন্ট
            লিষ্টের বাইরে কারো সাথে লেনদেন করলে আপনাকে নিজ দ্বায়িত্বে লেনদেন
            করতে হবে। লিষ্ট এর বাইরে কোন এজেন্ট এর দায়ভার{" "}
            <a href="linktoagents.com">linktoagents.com</a> নিবে না। কিন্তু
            লিস্টে অন্তর্ভুক্ত সকল এজেন্টদের সব দায়ভার{" "}
            <a href="linktoagents.com">linktoagents.com</a> এর এডমিন এর।{" "}
            <a href="linktoagents.com">linktoagents.com</a> এর এজেন্ট দের সাথে
            দুই ভাবে যোগাযোগ করতে পারবেন। ফেসবুক এবং হোয়াট্সঅ্যাপ। আপনি নিচের
            ফেসবুক গ্রুপে যুক্ত হয়েও সকল এজেন্টদের সাথে যোগাযোগ করতে পারেন।
            <br />
            <br />
            <small> ➡️ অনলাইন মাস্টার এজেন্ট গ্রুপ। </small> <br />
            এজেন্ট সম্পর্কে কোন অভিযোগ থাকলে সরাসরি কাস্টমার সার্ভিসে যোগাযোগ
            করতে পারেন অবথা
            <strong>এডমিন </strong>
            কে জানাতে পারেন।
            {/* <br />
            <br />
            🎯 আমি কি এজেন্ট হতে পারবো ?<br />
            জ্বী অবশ্যই হতে পারবেন। সেজন্য আপনাকে সুপার এজেন্টদের সাথে যোগাযোগ
            করতে হবে।
            <br />
            <br />
            এজেন্ট হওয়ার শর্তঃ- <br />
            ➡️ বিশ হাজার টাকা (২০,০০০/-) জামানত।
            <br />
            ➡️ কমপক্ষে ৫০০ কয়েন কিনে এজেন্টশিপ চালাতে হবে।
            <br />⛔ উক্ত শর্ত দুটি পুরন করতে না পারলে কখনোই সুপার এজেন্টদের নক
            দিবেন না।
            <br />
            <br />
            এজেন্ট পয়েন্ট রেটঃ- <br />
            এজেন্টরা সুপার এজেন্টদের কাছ থেকে ৭৫ টাকা করে কয়েন কিনবে পারবে এবং
            ১০০ টাকা করে ইজারদের কাছে বিক্রি করবে।
            <br />
            <br />
            এজেন্টদের লাভ লসঃ- <br />
            এজেন্টরা কয়েন প্রতি ২৫% লাভ পাবে। লাভ এবং লস বিস্তারিত জানতে সুপার
            এজেন্টদের নক করুন ।
            <br />
            <br />
            👉 সুপার এজেন্ট লিস্ট <br /> <br />
            🎯 কিভাবে কয়েন কিনবো? <br /> এজেন্টের কাছ থেকে আপনাকে কয়েন কিনতে
            হবে। কিন্তু কয়েন কিনার আগে অবশ্যই আপনার পছন্দের এজেন্টের কাছ থেকে
            একাউন্ট করিয়ে নিবেন। কয়েন বেচা এবং কেনার কাজ এজেন্টই করে দিবে।
            এজেন্ট আপনাকে একাউন্ট খোলার নিয়ম বলে দিবে। এজেন্ট কে ১০ কয়েন এর
            টাকা দিলে, এজেন্ট আপনাকে একাউন্ট খুলে দিবে এবং আপনার একাউন্টে ১০
            কয়েন ডিপোজিট করে দিবে। আমাদের সাইটে সর্বনিম্ন ডিপোজিট এবং উইথড্র ১
            হাজার টাকা – মানে ১০ পয়েন্ট।
            <br />
            <br />
            👉 অনলাইন মাস্টার এজেন্ট লিস্ট */}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuestionPage;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question11 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            এখানে কি বাজি ধরা যায়? <br /> <br />
            আপনি এজেন্টদের সাথে সরাসরি কথা বলে বাজির বিস্তারিত জানতে পারবেন।
            আমাদের এজেন্টরা ট্রাস্টেড এবং আপনি নিরাপদে বেটিং করতে পারবেন। তবে
            যেকোনো বাজি ধরার আগে জেনে নিন আপনার এজেন্টের নাম এজেন্ট লিস্টে রয়েছে
            কিনা। যেকোন অভিযোগের জন্য এজেন্ট লিস্টে এজেন্ট এর নামের পাশে অভিযোগ
            বাটনের উপর ক্লিক করে আপনার অভিযোগ করুন।
            <br /> <br />
            <br />
            <br />
            <small>➡️ এখানে কি কি বাজি ধরা যায়?</small>
            <br />
            আপনি অনেক ধরনের বাজিই ধরতে পারবেন
            <br />
            <br />
            ➡️ যেকোনো ক্রিকেট ম্যাচে বাজি ধরতে পারবেন। প্রতি ম্যাচে, ওভারে বা
            বলে বলেও বাজি ধরতে পারেন। বাজি ধরার জন্য রয়েছে শত শত বেটিং অপশন।
            <br />
            <br />
            ➡️ IPL, BPL, PSL, Big Bash, T10 সহ সকল লিগের ম্যাচেও একইভাবে বাজি
            ধরতে পারবেন।
            <br />
            <br />
            ➡️ সকল ফুটবল এবং টেনিস ম্যাচেও বাজি লাগাতে পারবেন।
            <br />
            <br />
            ➡️ এছাড়াও রয়েছে হাজারো রকমের লাইভ ক্যাসিনো, টেবিল গেম, স্লট গেম,
            ফিশিং গেম ই গেম ইত্যাদি।
            <br />
            <br />
            ➡️ তিনপাত্তি সহ তাসের বাজি গুলোতে বেটিং করতে পারবেন।
            <br />
            <br />
            এছাড়াও আরো অনেক মজার মজার বাজি রয়েছে, যেগুলোতে আপনি অনায়াসে বাজি
            ধরতে পারবেন। আরো বিস্তারিত জানতে Baaji365site-এর ওয়েবসাইটে ঘুরে
            আসুন।
            <br />
            <br />
            {/* <small> ➡️Baaji365 ওয়েবসাইট।</small> */}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question11;

import React, { useState, useEffect } from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "./../../Component/Navigation/Navigation";
import messenger_icon from "../../images/messanger.png";
import whatsappPic from "../../images/whatsapp.jpg";
import "./MasterAgentList.css";

const CustomerServiceList = () => {
  const [masterAgent, setMasterAgent] = useState([]);
  const [main, setMain] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("./CustomerService.JSON")
      .then((res) => res.json())
      .then((data) => {
        setMasterAgent(data);
      });
  }, []);

  useEffect(() => {
    setMain(shuffleArray(masterAgent));
  }, [masterAgent]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };
  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();

    const filterValue = masterAgent.filter((onlineAg) => onlineAg.id == search);
    setMain(filterValue);
  };

  return (
    <>
      <>
        <Navigation />
        <div className="admin-list-main-div">
          <div className="admin-list-color">
            <div className="admin-list">
              <h1>CUSTOMER SERVICE LIST </h1>

              {/* search bar  */}
              <form onSubmit={handleSearch} className="search-agent">
                {/* <p>Search</p> */}
                <input
                  type="text"
                  placeholder="Search ID or Name"
                  onChange={handleChange}
                  value={search}
                />
                <button type="submit">
                  <i className="fa-solid fa-magnifying-glass search-icon"></i>
                </button>
              </form>

              <div className="tabel-list">
                <table className="tabel-desc">
                  <thead>
                    <tr className="tabel-head">
                      <td>TYPE</td>
                      <td>ID</td>
                      <td>NAME</td>
                      <td>CONTACT</td>
                      <td>COMPLAIN</td>
                    </tr>
                  </thead>
                  <td colSpan={5} className="heading_line">
                    <p>
                      ।।হোয়াটসঅ্যাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা
                      লেনদেন করা যাবে না ।।
                    </p>
                    <small>এবং করলে তা গ্রহনযোগ্য হবে না</small>
                  </td>
                  <tbody>
                    {main.map((value, index) => {
                      return (
                        <>
                          <tr
                            className="table-body-td"
                            style={{ backgroundColor: value.bgColor }}
                            key={index}
                          >
                            <td>{value.type}</td>
                            <td>{value.id}</td>
                            <td>{value.name}</td>
                            <td>
                              <div className="social-icon">
                                {/* <a href={value.facebook}>
                                <img src={facebook_icon} alt="" />
                              </a> */}
                                {/* <a href={value.messenger}>
                                  <img src={messenger_icon} alt="" />
                                </a> */}

                                <a href={value.whatsapp}>
                                  <img src={whatsappPic} alt="" />
                                </a>
                              </div>
                            </td>
                            <td>
                              <a
                                href={value.complain}
                                className="complain-text"
                              >
                                {value.complainName}
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default CustomerServiceList;

import React, { useEffect, useState } from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";
import messengerPic from "../../images/messanger.png";
import whatsappPic from "../../images/whatsapp.jpg";

const EmergencyServices = () => {
  const [emergency, setEmergency] = useState([]);
  useEffect(() => {
    fetch("./EmergencyList.JSON")
      .then((res) => res.json())
      .then((data) => setEmergency(data));
  }, []);
  return (
    <>
      <Navigation />
      <div className="admin-list-main-div">
        <div className="admin-list-color">
          <div className="admin-list">
            <h1>HELPLINE</h1>

            <div className="tabel-list">
              <table className="tabel-desc">
                <thead>
                  <tr className="tabel-head">
                    <td>TYPE</td>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Contact</td>
                  </tr>
                </thead>
                <tbody>
                  {emergency.map((value, index) => {
                    return (
                      <tr className="table-body-td" index={value.id}>
                        <td>{value.type}</td>
                        <td>{value.id}</td>
                        <td>{value.name}</td>
                        <td>
                          <div className="social-icon">
                            <a href={value.messenger}>
                              <img src={messengerPic} alt="" />
                            </a>

                            <a href={value.whatsapp}>
                              <img src={whatsappPic} alt="" />
                            </a>
                          </div>
                        </td>
                        {/* <td>
                                                        <a href={value.complain} className='complain-text'>{value.complainName}</a>
                                                    </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmergencyServices;

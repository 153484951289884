import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "./../../Component/Navigation/Navigation";
import { Link } from "react-router-dom";
import adminImg from "../../images/FACEBOOK.jpg";
import masterFooter from "../../images/agent_list.jpg";
import superIcon from "../../images/icons_.png";
import "./MasterAgentPage.css";

const MasterAgentPage = () => {
  return (
    <>
      <Navigation />

      <div>
        <div className="admin-background">
          <img src={adminImg} alt="" />
          <div className="opacity-color"></div>
          <div className="admin-background-info master-agent">
            <img className="super_img__" src={superIcon} alt="" />
            <a className="link_dres" href="/masterAgentList">
              {" "}
              <h1>মাস্টার এজেন্ট</h1>
            </a>
          </div>
        </div>
        <div className="admin-details">
          <div className="admin-details-list">
            <p>
              Baaji365 এর সকল মাস্টার এজেন্ট এখানে রয়েছেন। মাস্টার এজেন্টদের
              সাথে যোগাযোগ করতে নিচের এজেন্ট লিস্টে ক্লিক করে তাদের ম্যাসেঞ্জার
              বা WhatsApp আইকনে ক্লিক করুন । যেকোনো অভিযোগের জন্য COMPLAIN এ
              যোগাযোগ করুন।
            </p>

            <Link to="/masterAgentList">MASTER LIST</Link>
          </div>

          <div className="admin-details-img">
            <img src={masterFooter} alt="" />
            <div className="content"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MasterAgentPage;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminList from "./Pages/AdminList/AdminList";
import AdminPage from "./Pages/AdminPage/AdminPage";

import EmergencyServices from "./Pages/EmergencyServices/EmergencyServices";
import Home from "./Pages/Home/Home";
import MasterAgentList from "./Pages/MasterAgentList/MasterAgentList";
import MasterAgentPage from "./Pages/MasterAgentPage/MasterAgentPage";
import QuestionPage from "./Pages/QuestionPage/QuestionPage";
import SuperAgentList from "./Pages/SuperAgentList/SuperAgentList";
import SuperAgentPage from "./Pages/SuperAgentPage/SuperAgentPage";
import AgentListQuestion from "./Pages/AgentListQuestion/AgentListQuestion";
import AgentListQuestion2 from "./Pages/AgentListQuestion2/AgentListQuestion2";
import AgentListQuestion3 from "./Pages/AgentListQuestion2/AgentListQuestion3";
import UserCollection from "./Component/UserCollection/UserCollection.jsx";
import SiteUpdate from "./Component/SiteUpdate/SiteUpdate.jsx";
import Question2 from "./Pages/QuestionPage/Question2";
import Question3 from "./Pages/QuestionPage/Question3";
import Question4 from "./Pages/QuestionPage/Question4";
import Question5 from "./Pages/QuestionPage/Question5";
import Question6 from "./Pages/QuestionPage/Question6";
import Question7 from "./Pages/QuestionPage/Question7";
import Question8 from "./Pages/QuestionPage/Question8";
import Question9 from "./Pages/QuestionPage/Question9";
import Question10 from "./Pages/QuestionPage/Question10";
import Question11 from "./Pages/QuestionPage/Question11";
import Question12 from "./Pages/QuestionPage/Question12";
import SubAdminPage from "./Pages/AdminPage/SubAdminpage.jsx";
import SubAdminList from "./Pages/AdminList/SubAdminList.jsx";
import CustomerServicePage from "./Pages/MasterAgentPage/CustomerServicePage.jsx";
import CustomerServiceList from "./Pages/MasterAgentList/CustomerServiceList.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="question" element={<QuestionPage />} />
        <Route path="adminPage" element={<AdminPage />} />
        <Route path="SubadminPage" element={<SubAdminPage />} />
        <Route path="adminList" element={<AdminList />} />
        <Route path="customerServicePage" element={<CustomerServicePage />} />
        <Route path="customerServiceList" element={<CustomerServiceList />} />
        <Route path="subAdminList" element={<SubAdminList />} />
        <Route path="usercollection" element={<UserCollection />} />
        <Route path="siteupdate" element={<SiteUpdate />} />
        <Route path="superAgentPage" element={<SuperAgentPage />} />
        <Route path="superAgentList" element={<SuperAgentList />} />
        <Route path="masterAgentPage" element={<MasterAgentPage />} />
        <Route path="masterAgentList" element={<MasterAgentList />} />
        <Route path="emergencyService" element={<EmergencyServices />} />
        <Route path="agentListQuestion" element={<AgentListQuestion />} />
        <Route path="agentListQuestion2" element={<AgentListQuestion2 />} />
        <Route path="agentListQuestion3" element={<AgentListQuestion3 />} />
        <Route path="question2" element={<Question2 />} />
        <Route path="question3" element={<Question3 />} />
        <Route path="question4" element={<Question4 />} />
        <Route path="question5" element={<Question5 />} />
        <Route path="question6" element={<Question6 />} />
        <Route path="question7" element={<Question7 />} />
        <Route path="question8" element={<Question8 />} />
        <Route path="question9" element={<Question9 />} />
        <Route path="question10" element={<Question10 />} />
        <Route path="question11" element={<Question11 />} />
        <Route path="question12" element={<Question12 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

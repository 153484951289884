import React from "react";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import "./UserCollection.css";
import point from "../../images/correct.svg";

const UserCollection = () => {
  return (
    <>
      <Navigation />
      <div className="userQuestion_heading">
        <h2>Baaji365 ইউজারদের সকল প্রশ্নের উওর পেয়ে যাবেন নিচের লিংক থেকে</h2>
        <div className="hyperlink">
          <p></p>
        </div>
      </div>

      <div className="question_heading">
        <h3>Baaji365 সম্পর্কে জানুন</h3>
        <h4>BANGLADESH NUMBER ONE BETTING SITE</h4>
      </div>
      <div className="question_section">
        <ul>
          <li>
            <img src={point} alt="" />
            <a href="/question"> একাউন্ট কিভাবে খুলবো?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question3"> পয়েন্ট কিনবো কীভাবে ?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question2"> আমি কি এজেন্ট হতে পারবো ?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question4"> এজেন্ট কিভাবে লেনদেন করবেন?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question5"> লেনদেন করবো কিভাবে ?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question6"> বিকাশ/নগদে কি লেনদেন হয়?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question7">
              {" "}
              একাউন্ট কিভাবে খুলবো?যদি আমি কয়েন উইন হই তাহলে টাকা কিভাবে পাবো?
            </a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question8"> ফেসবুক পেইজে কিভাবে যুক্ত হবো?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question9"> এখানে কি কি খেলা আছে?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question10"> ফেসবুক গ্রুপে কিভাবে যুক্ত হবো?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question11">Baaji365 এর সকল ওয়েবসাইটে কিভাবে যাবো?</a>
          </li>
          <li>
            <img src={point} alt="" />
            <a href="/question12"> কিভাবে বাজি ধরবো??</a>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default UserCollection;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question12 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            কিভাবে Baaji365-এর ওয়েবসাইটে যাবো? <br /> <br />
            আপনি এখানে ক্লিক করে <strong>Baaji365site</strong> এর ওয়েবসাইটে
            সরাসরি চলে যেতে পারেন। তারপর সেখানে নিরাপদে বেটিং করতে পারেন। বেটিং
            করার আগে আপনার একাউন্ট খুলে নিন। একাউন্ট খুলতে নিচে ক্লিক করুন।
            <br />
            <br />
            ➡️ <strong>Baaji365</strong> ওয়েবসাইট।
            <br />
            <br />
            ➡️<strong>Facebook Group </strong>
            <br />
            <br />
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question12;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question9 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            ফেসবুক গ্রুপে কিভাবে যুক্ত হবো? <br />
            কিভাবে Baaji365site - এর ওয়েবসাইটে যাবো?
            <br />
            <br />
            আপনি নিচের লিংকে ক্লিক করলেই সরাসরি ফেইসবুক গ্রুপে চলে যাবেন।
            ফেইসবুকে ঢুকে গেলে তারপর Join -এ ক্লিক করে যুক্ত হয়ে যান।
            <br />
            <br />
            <small>➡️ ফেসবুক গ্রুপ</small>
            <br />
            ফেইসবুক গ্রুপে সকল এজেন্ট, সুপার এজেন্ট, সাব এডমিন এবং এডমিনরা
            রয়েছে। আপনার যেকোনো সমস্যা বা প্রয়োজনে আপনি সবাইকে পাশে পাবেন।
            <br />
            <br />
            <small>
              <a href="/https://baaji365.site/">https://baaji365.site/</a>
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question9;

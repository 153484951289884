import React from "react";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

const SiteUpdate = () => {
  return (
    <>
      <Navigation />

      <Footer />
    </>
  );
};

export default SiteUpdate;

import React from "react";
import "./GeneralQuestion.css";
import { NavLink } from "react-router-dom";
import iconI from "../../images/icon-i.png";

const GeneralQuestion = () => {
  return (
    <div className="general-quesiton-main-div">
      <div className="general-question-div"></div>
      <div className="general-question-details">
        <h1> ইউজারদের সাধারন প্রশ্ন ও উত্তর </h1>

        <div className="general-Questions">
          <div className="general-question">
            <ul>
              <li>
                <NavLink to="/question">কিভাবে একাউন্ট খুলবেন ?</NavLink>
              </li>
              <li>
                <NavLink to="/question2">আমি কি এজেন্ট হতে পারব?</NavLink>
              </li>
              <li>
                <NavLink to="/question3">কিভাবে কয়েন কিনবো?</NavLink>
              </li>
            </ul>
          </div>

          {/* 2nd question  */}
          <div className="general-question">
            <ul>
              <li>
                <NavLink to="/question4">লেনদেন কিভাবে করবো?</NavLink>
              </li>
              <li>
                <NavLink to="/question5">বিকাশ/নগদে কি লেনদেন হয়?</NavLink>
              </li>
              <li>
                <NavLink to="/question6">আমি কি কয়েন জিতলেই টাকা পাবো?</NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* 2nd slot */}
        <div className="general-Questions" id="second_slot">
          <div className="general-question">
            <ul>
              <li>
                <NavLink to="/question7">এজেন্ট লিস্ট কোথাই পাবো?</NavLink>
              </li>
              <li>
                <NavLink to="/question8">
                  এজেন্টদের সাথে কিভাবে যোগাযোগ করবো?
                </NavLink>
              </li>
              <li>
                <NavLink to="/question9">
                  ফেসবুক গ্রুপে কিভাবে যুক্ত হবো?
                </NavLink>
              </li>
            </ul>
          </div>

          {/* 2nd question  */}
          <div className="general-question">
            <ul>
              <li>
                <NavLink to="/question10">কিভাবে বাজি ধরবো?</NavLink>
              </li>
              <li>
                <NavLink to="/question11">এখানে কি কি বাজি ধরা যায়?</NavLink>
              </li>
              <li>
                <NavLink to="/question12">
                  কিভাবে baaji365 আন্তর্জাতিক ওয়েবসাইটে যাব?
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralQuestion;

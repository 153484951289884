import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question5 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> বিকাশ/নগদে কি লেনদেন হয়?</small> <br />
            ➡️জ্বী সাধারণত সকল প্রকার লেনদেন ই বিকাশ/নগদ/রেকট/উপায় সহ ব্যাংকের
            মাধ্যমে হয়। আপনি এজেন্টদের সাথে যোগাযোগ করে লেনদেন করুন। আপনি যদি
            কার্ডে মাধ্যমে লেনদেন করতে চান তাও সম্ভব। আপনি এজেন্টদের সাথে
            যোগাযোগ করে, কার্ডের মাধ্যমে লেনদেন করতে পারেন।
            <br />
            <br />
            {/* ➡️বিকাশ/নগদ/রকেটঃ ** অনেকেই আছেন – নিজের বিকাশ এজেন্ট নাই বা নিজের
            বিকাশ এজেন্ট এ লেনদেন করতে রিস্ক মনে করেন। সেই ক্ষেত্রে কোম্পানীর
            বিকাশ এজেন্ট ব্যবহার করতে পারেন। যদি কোম্পানীর বিকাশ – নগদ এজেন্ট
            ব্যবহার করেন তাহলে কয়েনের দাম একটু বেশি হবে । */}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question5;

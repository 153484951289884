import React from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";

const AgentListQuestion = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            {" "}
            🎯লেনদেন কিভাবে করবো?
            <br />
            <br />
            আপনি আপনার সকল লেনদেন আপনার এজেন্টের সাথে করবেন। এজেন্ট ব্যাতিত অন্য
            কারো সাথে ভুলেও লেনদেন করবেন না। আপনি বিকাশ/নগদ/রকেটে লেনদেন করতে
            পারেন। সেক্ষেত্রে এজেন্টের সাথে পরামর্শ করে নিবেন।লেনদেনর ব্যাপারে
            নিচের নির্দেশনা গুলো পড়ে নিন-
            <br />
            <br />
            বিকাশ/নগদ/রকেটঃ ** অনেকেই আছেন – নিজের বিকাশ এজেন্ট নাই বা নিজের
            বিকাশ এজেন্ট এ লেনদেন করতে রিস্ক মনে করেন। সেই ক্ষেত্রে কোম্পানীর
            বিকাশ এজেন্ট ব্যবহার করতে পারেন। যদি কোম্পানীর বিকাশ – নগদ এজেন্ট
            ব্যবহার করেন তাহলে কয়েনের দাম একটু বেশি হবে ।
            <br />
            <br />
            🎯 বিকাশ/নগদে কি লেনদেন হয়? <br /> <br /> জ্বী সাধারণত সকল লেনদেন ই
            বিকাশ/নগদে হয়। আপনি এজেন্টদের সাথে যোগাযোগ করে লেনদেন করুন। আপনি যদি
            কার্ডে কয়েন কিনতে চান তাহলেও সম্ভব। আপনি এজেন্টদের সাথে যোগাযোগ করে
            কার্ড থেকেও লেনদেন করতে পারেন।
            <br />
            <br />
            বিকাশ/নগদ/রকেটঃ ** অনেকেই আছেন – নিজের বিকাশ এজেন্ট নাই বা নিজের
            বিকাশ এজেন্ট এ লেনদেন করতে রিস্ক মনে করেন। সেই ক্ষেত্রে কোম্পানীর
            বিকাশ এজেন্ট ব্যবহার করতে পারেন। যদি কোম্পানীর বিকাশ – নগদ এজেন্ট
            ব্যবহার করেন তাহলে কয়েনের দাম একটু বেশি হবে ।
            <br />
            <br /> 🎯 আমি কি কয়েন জিতলেই টাকা পাবো? <br /> <br /> জ্বী আপনি কয়েন
            জিতলেই উইড্র করে এজেন্টদের কাছ থেকে টাকা নিয়ে নিতে পারেন। তবে আপনার
            সর্বনিম্ন উইড্র হচ্ছে ১০ কয়েন বা ১০০০ টাকা।বিস্তারিত জানতে এজেন্টের
            সাথে কথা বলুন।নিচে এজেন্ট লিস্ট দেয়া আছে। এজেন্ট লিস্টে গিয়ে তাদের
            নামের উপর ক্লিক করলেই সরাসরি তাদের সাথে ফেইসবুকে কথা বলতে পারবেন
            অথবা নাম্বারের উপর ক্লিক করলেই সরাসরি হোয়াটসঅ্যাপে যোগাযোগ করতে
            পারেন।
            <br />
            <br />
            👉 অনলাইন মাস্টার এজেন্ট লিস্ট
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AgentListQuestion;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question8 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            <small> এজেন্টদের সাথে যোগাযোগ করবো কিভাবে?</small> <br />
            আপনি এজেন্টদের সাথে যোগাযোগ করতে পারেন শুধুমাত্র হোয়াটসঅ্যাপে। আর
            হোয়াটসঅ্যাপে কথা বলতে নিচের এজেন্ট লিস্টে ঢুকে হোয়াটসঅ্যাপ আইকনের
            উপর ক্লিক করুন। তারপর এজেন্টকে মেসেজ দিন।
            <br />
            <br />
            <small>➡️ অনুমোদিত এজেন্টদের লিস্ট।</small>
            <br />
            এই এজেন্ট লিষ্টের বাইরে কারো সাথে লেনদেন করলে আপনাকে নিজ দ্বায়িত্বে
            লেনদেন করতে হবে। লিষ্ট এর বাইরে কোন এজেন্ট এর দায়ভার{" "}
            <strong>Baaji365site</strong> নিবে না এবং লিষ্ট এর এজেন্ট দের সব
            দায়ভার <strong>Baaji365site</strong> কোম্পানীর। এজেন্ট লিষ্ট এর
            এজেন্ট দের সাথে শুধুমাত্র হোয়াটসঅ্যাপে যোগাযোগ করতে পারেন। আপনি
            নিচের ফেইসবুক গ্রুপে যুক্ত হয়ে ও সকল এজেন্টদের সাথে যোগাযোগ করতে
            পারেন।
            <br />
            <br />
            <small>➡️ অনলাইন মাস্টার এজেন্ট গ্রুপ।</small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question8;

import React from "react";
import Navigation from "../../Component/Navigation/Navigation";
import Footer from "../../Component/Footer/Footer";

const Question10 = () => {
  return (
    <>
      <Navigation />
      <div className="question-main-div">
        <div className="question-section">
          <p>
            কিভাবে বাজি ধরবো? <br /> <br />
            আপনি এজেন্টদের কাছ থেকে একাউন্ট খুলে ডিপোজিট করে নিন। তারপর বাজি
            ওয়েবসাইটে গিয়ে আপনার প্লেয়ার একাউন্টে লগইন করে আপনার পছন্দ মতো বাজি
            ধরতে পারবেন।
            <br />
            <br />
            <small>➡️ এখানে বাজি ধরুন</small>
            <br />
            আপনি যদি না জানেন কিভাবে বাজি ধরতে হয় তাহলে আপনি আপনার এজেন্টের সাথে
            যোগাযোগ করুন। এজেন্ট আপনাকে পরিষ্কার ভাবে বুঝিয়ে দিবেন।
            <br />
            <br />
            <strong>Verified Agent List of Baaji365site</strong>
            <br />
            <br />
            <small>
              <a href="www.linktoagents.com">www.linktoagents.com</a>
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Question10;

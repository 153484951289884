import React, { useEffect, useState } from "react";
import Footer from "../../Component/Footer/Footer";
import Navigation from "../../Component/Navigation/Navigation";
import "./AdminList.css";
import messenger_icon from "../../images/messanger.png";
import whatsappPic from "../../images/whatsapp.jpg";

const SubAdminList = () => {
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    fetch("./SubAdmin.JSON")
      .then((res) => res.json())
      .then((data) => setAdmin(data));
  }, []);
  return (
    <>
      <Navigation />
      <div className="admin-list-main-div">
        <div className="admin-list-color">
          <div className="admin-list">
            <h1>SUB ADMIN</h1>

            <div className="tabel-list">
              <table className="tabel-desc">
                <thead>
                  <tr className="tabel-head">
                    <td>TYPE</td>
                    <td>ID</td>
                    <td>Contact</td>
                    <td>NUMBER</td>
                    <td>COMPLAIN</td>
                  </tr>
                </thead>
                <td colSpan={5} className="heading_line">
                  <p>
                    ।।হোয়াটসঅ্যাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা
                    লেনদেন করা যাবে না ।।
                  </p>
                  <small>এবং করলে তা গ্রহনযোগ্য হবে না</small>
                </td>
                <tbody>
                  {admin.map((value, index) => {
                    return (
                      <tr
                        className="table-body-td"
                        style={{ backgroundColor: value.bgColor }}
                        index={value.id}
                      >
                        <td>{value.type}</td>
                        <td>{value.id}</td>

                        <td>
                          <div className="social-icon">
                            {/* <a href={value.facebook}>
                              <img src={facebook_icon} alt="" />
                            </a> */}
                            {/* <a href={value.messenger}>
                              <img src={messenger_icon} alt="" />
                            </a> */}

                            <a href={value.whatsapp}>
                              <img src={whatsappPic} alt="" />
                            </a>
                          </div>
                        </td>
                        <td>{value.number}</td>
                        <td>
                          <a className="adminComplain" href={value.complain}>
                            Complain
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubAdminList;
